@import 'resets';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@400;700&display=swap');

@include html-reset;
@include print-reset;

@font-face {
  font-family: 'Gotham Book';
  src: url('../assets/fonts/GothamBook/GothamBook.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/GothamBook/GothamBook.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('../assets/fonts/GothamBook/GothamBook.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('../assets/fonts/GothamBold/GothamBold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/GothamBold/GothamBold.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/GothamBold/GothamBold.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('../assets/fonts/GothamBold/GothamBold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Gotham Black';
  src: url('../assets/fonts/GothamBlack/GothamBlack.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/GothamBlack/GothamBlack.woff') format('woff'),
    /* Pretty Modern Browsers */
      url('../assets/fonts/GothamBlack/GothamBlack.ttf') format('truetype'); /* Safari, Android, iOS */
}
